.loader {


    margin: auto;


    border: 10px solid #EAF0F6;


    border-radius: 50%;


    border-top: 10px solid #FF7A59;


    width: 64px;


    height: 64px;


    animation: spinner 4s linear infinite;


}





@keyframes spinner {


    0% {
        transform: rotate(0deg);
    }


    100% {
        transform: rotate(360deg);
    }


}